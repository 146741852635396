import Image from "next/image";
import LogoWhite from "./logo-white.svg";
import routes from "@/config/landingPage/routes";
interface FooterProps {
    hasContactForm?: boolean;
  }
const Footer = ({
    hasContactForm = true
  }: FooterProps) => {
  return (
    <footer
      className="bg-adahubSecondary flex flex-row items-center justify-center"
      style={{ color: "white" }}>
      <div className={`${hasContactForm ? "mt-[380px]" : "mt-[64px]"} items-center justify-center flex-column flex  max-w-screen-lg px-4`}>
        <div className="col">
          <div className="mb-4">
            <Image src={LogoWhite} alt="adahub logo" />
          </div>
          <div className="mt-7 row">
            <div className="col-6 col-md-4">
              <h4 className="text-lg font-bold mb-9">Sản phẩm</h4>
              <div className="row flex-wrap text-sm gap-y-2">
                <div className="col-12 col-md-6 d-flex flex-column gap-3">
                  <a href={routes.products.crm} className="text-decoration-none text-white">
                    CRM Bán hàng
                  </a>
                  <a href={routes.products.omnichannel} className="text-decoration-none text-white">
                    Chat đa kênh
                  </a>
                  <a href={routes.products.callCenter} className="text-decoration-none text-white">
                    Tổng đài ảo
                  </a>
                  <a href={routes.products.callbot} className="text-decoration-none text-white">
                    Callbot AI UCall
                  </a>
                  <a href={routes.products.emailMarketing} className="text-decoration-none text-white">
                    Email Marketing
                  </a>
                </div>
                <div className="col-12 col-md-6 d-flex flex-column gap-3">
                  <a href={routes.products.messages} className="text-decoration-none text-white">
                    Chiến dịch nhắn tin
                  </a>
                  <a href={routes.products.telesales} className="text-decoration-none text-white">
                    Chiến dịch Telesales
                  </a>
                  <a href={routes.products.ulead} className="text-decoration-none text-white">
                    Nền tảng data ULead
                  </a>
                  <a href={routes.products.uflow} className="text-decoration-none text-white">
                    Tự động hóa UFlow
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-8 mb-4">
              <h4 className="text-lg font-bold mb-8">Giải pháp</h4>
              <div className="flex flex-row flex-wrap gap-x-4 text-sm">
                <div className="col-12 col-md-5">
                  <h5 className="text-base font-bold mb-3">Marketing</h5>
                  <div className="d-flex flex-column gap-3 mb-4">
                    <a href={routes.solutions.findLeads} className="text-decoration-none text-white">
                      Tìm kiếm data khách hàng tiềm năng
                    </a>
                    <a href="" className="text-decoration-none text-white">
                      Lead Generation
                    </a>
                    <a href={routes.solutions.filterCustomers} className="text-decoration-none text-white">
                      Lọc khách hàng tự động
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <h5 className="text-base font-bold mb-3">Bán hàng</h5>
                  <div className="d-flex flex-column gap-3 mb-4">
                    <a href={routes.solutions.salesManagement} className="text-decoration-none text-white">
                      Quản lý bán hàng
                    </a>
                    <a href={routes.solutions.telesales} className="text-decoration-none text-white">
                      Gọi điện Telesales
                    </a>
                    <a href={routes.solutions.leadNurturing} className="text-decoration-none text-white">
                      Lead Nurturing
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <h5 className="text-base font-bold mb-3">
                    Chăm sóc khách hàng
                  </h5>
                  <div className="d-flex flex-column gap-y-3">
                    <a href={routes.solutions.customerSupport} className="text-decoration-none text-white">
                      Quản lý hỗ trợ khách hàng
                    </a>
                    <a href={routes.solutions.telesales} className="text-decoration-none text-white">
                      Tự động CSKH sau bán
                    </a>
                    <a href="" className="text-decoration-none text-white">
                      Lấy feedback khách hàng
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-10">
            <div className="row">
              <div className="col-12 col-md-4 mr-2 hidden md:block">
                <h4 className="text-lg font-semibold mb-9">
                  CÔNG TY CỔ PHẦN GIẢI PHÁP ETRON
                </h4>

                <div className="flex flex-column gap-y-3 text-sm">
                  <div>
                    Văn phòng:
                    <br />
                    A24/D7 Khúc Thừa Dụ, Cầu Giấy, Hà Nội
                  </div>
                  <div>
                    Email:{" "}
                    <a href="mailto:contact@ucall.asia">contact@ucall.asia</a>
                  </div>
                  <div>
                    Hotline: <a href="tel:0249 9999 468">0249 9999 468</a>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-7 mb-4 flex flex-row flex-wrap">
                <div className="col-6 col-md-6">
                  <h4 className="text-lg font-semibold mb-9">Chính sách</h4>
                  <div className="row flex-wrap gap-3 text-sm">
                    <a href="/home/terms" className="text-decoration-none text-white">
                      Điều khoản sử dụng
                    </a>
                    <a href="/home/data" className="text-decoration-none text-white">
                      Chính sách bảo mật
                    </a>
                    <a href="/home/refund" className="text-decoration-none text-white">
                      Chính sách hoàn trả
                    </a>
                    <a href="#" className="text-decoration-none text-white">
                      Chính sách thanh toán
                    </a>
                  </div>
                </div>
                <div className="col-6 col-md-6">
                  <h4 className="text-lg font-semibold mb-9">Tài liệu</h4>
                  <div className="d-flex flex-column gap-3 text-sm">
                    <a href="https://ucall-jsc.sg.larksuite.com/wiki/Nr1IwB3F2iOArxkJyKBlLjh6gXb?from=from_copylink" className="text-decoration-none text-white">
                      Phiên bản cập nhật gần đây
                    </a>
                    <a href="#" className="text-decoration-none text-white">
                      Hướng dẫn sử dụng
                    </a>
                    <a href="https://ucall-jsc.sg.larksuite.com/share/base/form/shrlgQwSpSDg95owug1GbakXN7c" className="text-decoration-none text-white">
                      Góp ý cho UCALL
                    </a>
                    <a href="https://blog.adahub.vn/" className="text-decoration-none text-white">
                      UCALL Blog
                    </a>
                    <a href="https://affiliate.partner.ucall.vn/" className="text-decoration-none text-white">
                      Đối tác
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-14 flex flex-column align-items-center justify-center">
              <div className="bg-white h-[2px] w-full" />
              <div className="w-fit flex flex-row gap-x-3.5 bg-adahubSecondary mt-[-16px] px-4">
                <a href="https://www.facebook.com/ucall.vn/" target="_blank">
                  <img
                    className="max-w-[32px]"
                    src="https://img.icons8.com/color/48/000000/facebook-new.png"
                    alt="Facebook"
                  />
                </a>

                <a href="https://www.tiktok.com/@ucall.vn" target="_blank">
                  <img
                    className="max-w-[32px]"
                    src="https://img.icons8.com/color/48/000000/tiktok.png"
                    alt="TikTok"
                  />
                </a>

                <a
                  href="https://www.youtube.com/@ucall.autocall"
                  target="_blank">
                  <img
                    className="max-w-[32px]"
                    src="https://img.icons8.com/color/48/000000/youtube-play.png"
                    alt="YouTube"
                  />
                </a>

                <a href="https://zalo.me/167003496959089065" target="_blank">
                  <img
                    className="max-w-[32px]"
                    src="https://img.icons8.com/color/48/000000/zalo.png"
                    alt="Zalo"
                  />
                </a>

                <a
                  href="https://www.linkedin.com/company/ucall-jsc/posts/?feedView=all"
                  target="_blank">
                  <img
                    className="max-w-[32px]"
                    src="https://img.icons8.com/color/48/000000/linkedin.png"
                    alt="LinkedIn"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="text-center mt-6 mb-40 font-light">
            <p>© 2024 UCALL All Right Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;