"use client";

import HomePageMenu from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/HomePageMenu";
import AdahubGradientBackground from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/AdahubGradientBackground";
import { usePathname } from "next/navigation";
import routes from "@/config/landingPage/routes";
import React from "react";
import Footer from "./components/Footer";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname();
  const noGradient = [routes.contactUs, routes.pricing].includes(pathname);
  const noContactForm = [
    routes.contactUs,
    routes.pricing,
    routes.paymentPage,
    routes.pricelist,
    routes.data,
    routes.terms,
    routes.refund,
  ].includes(pathname);
  const solutionsLayout = pathname.includes("/solutions/");
  let _layout;
  if (noGradient) {
    _layout = (
      <div>
        <HomePageMenu />
        {children}
        <Footer hasContactForm={!noContactForm} />
      </div>
    );
  } else {
    if (solutionsLayout) {
      _layout = (
        <AdahubGradientBackground color="blue">
          <HomePageMenu />
          {children}
          <Footer hasContactForm={!noContactForm} />
        </AdahubGradientBackground>
      );
    } else {
      _layout = (
        <AdahubGradientBackground color="orange">
          <HomePageMenu />
          {children}
          <Footer hasContactForm={!noContactForm} />
        </AdahubGradientBackground>
      );
    }
  }

  return <>{_layout}</>;
};

export default Layout;
